@import url("https://fonts.googleapis.com/css2?family=Cairo:wght@400;600;700&family=Open+Sans&display=swap");

body {
    font-family: 'Poppins', sans-serif;
}

.button1 {
  padding: 8px 16px;
  margin: 4px;
  cursor: pointer;
  border: 1px solid #ccc;
  background-color: #fff;
  color: #333;
  border-radius: 5px;
}

.button2 {
  padding: 8px 16px;
  margin: 4px;
  cursor: pointer;
  border: 1px solid #ccc;
  background-color: #fff;
  color: #333;
  border-radius: 5px;
}

.button1.selected {
  background-color: #34d399;
  color: #fff;
}

.button2.selected {
  background-color: #34d399;
  color: #fff;
}

.navbar {
    background: #fff;
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.2rem;
    position: sticky;
    top: 0;
    z-index: 999;
  }

  .nav-outerbox {
    background: #34d399;
    height: 20px;
  }

  .navbar-dropdown {
    display: flex;
    flex-direction: row;
    background: #fff;
    height: 200px;
    width: full;
    margin-top: 0px;
  }

  .navbar-flex-container {
    display: flex;
    margin-top: 20px;
  }

  .dropdown-container{
    padding-left: 3rem;
    padding-right: 3rem;
    color: #fff;
  }

  .dropdown-header {
    font-weight: bold;
    font-size: large;
    color: #000;
  }

  .dropdown-item {
    font-weight: lighter;
    color: #000;
  }
  
  .navbar-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 80px;
    max-width: 1500px;
  }
  
  .navbar-logo {
    color: #000;
    font-weight: bold;
    justify-self: start;
    margin-left: 20px;
    cursor: pointer;
    text-decoration: none;
    font-size: 2rem;
    display: flex;
    align-items: center;
  }
  
  
  
  .nav-menu {
    display: grid;
    grid-template-columns: repeat(4, auto);
    grid-gap: 10px;
    list-style: none;
    text-align: center;
    width: 60vw;
    justify-content: end;
    margin-right: 1rem;
  }
  
  .nav-item {
    height: 80px;
  }
  
  .nav-links {
    color: #000;
    display: flex;
    align-items: center;
    text-decoration: none;
    padding: 0.5rem 1rem;
    height: 100%;
  }
  
  .nav-links:hover {
    color: #34d399;
    font-weight: bold;
    transition: all 0.2s ease-out;
  }
  
  .fa-bars {
    color: #fff;
  }
  
  .nav-links-mobile {
    display: none;
  }
  
  .menu-icon {
    display: none;
  }

  .hero-tagline-box {
    background-color: white;
    border-radius: 10px;
    margin-left: 15rem;
    margin-top: 3rem;
    padding-left: 5px;
    padding-right: 5px;
    padding-top: 15px;
    padding-bottom: 5px;
    height: 160px;
    width: 310px;
    text-align: left;
  }

  .hero-tagline1 {
    font-weight: 800;
    font-size: 26px;
    color: #34d399;
  }

  .hero-tagline2 {
    font-weight: 800;
    font-size: 26px;
  }
 
  .hero-image {
    transform: translateY(-170px) scale(0.5);
    margin-left: 155%;
  }

  .hero-components {
    display: flex;
    flex-direction: row;
  }

  .hero-background {
    width: 100%;
    height: 490px;
  }
  
 .searchbar-style {
  display: flex;
  flex-direction: row;
  background-color: #fff;
  padding-left: 2px;
  margin-left: 15rem;
  height: 30px;
  border-radius: 5px;
  margin-top: 250px;
 }

 .search-select-service{
  color: gray;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 150px;
  min-width: 100px;
 }

 .searchbar-button{
  display: flex;
  flex-direction: row;
  margin-left: 5px;
  
 }

 .searchbar-button-booknow {
  background-color: #34d399;
  border-radius: 5px;
  height: 30px;
  padding-left: 5px;
  padding-right: 5px;
  align-self: flex-end;
 }

 .send-icon {
  margin-left: 5px;
 }

 .searchbar-input {
  margin-left: 10px;
  width: 100px;
  padding-top: 2px;
 }

 .searchbar-dropdown{
  z-index: 1000 !important;
 }

 .service-tab {
  background-color: grey;
  border-radius: 10px;
  position: absolute;
  display: flex;
  flex-direction: row;
  padding: 5px 5px 5px 5px;
 }

 .service-option {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  max-width: 100px;
 }

 .service-option-image{
  height: 30px;
  width: 30px;
  padding-left: 10px;
 }

.service-option-label{
  font-size: 8px;
  overflow-wrap: break-word;
}

 .service-option-styles{
  margin-left: 10px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
 }

 .service-stack{
  display: flex;
  flex-direction: column;
  padding: 5px 5px 5px 5px;
 }

 .service-box{
  background-color: #D9D9D9;
  margin-left: 20px;
  margin-right: 20px;
  border-radius: 10px;
  padding-left: 10px;
 }

 .service-item {
  align-items: center;
  justify-items: center;
 }

 .service-icon{
  height: 20px;
  width: 20px;
  padding-left: 5px;

 }
  
  .service-title {
    font-size: 7px;
  }

  .whyapac-header{
    display: flex;
    text-align: center;
    font-size: large;
    justify-items: center;
    margin-top: 4px;
  }

  .whyapac-image{
    flex-direction: column;
  }

  .whyapac-text{
    flex-direction: column;
  }

  .why-apac-image-box{
    height: 80px;
    width: 100px;
  }
 
  .whyapac-flex-container{
    flex-direction: row;
  }
  .why-apac-descriptionbox{
    background-color: #5eead4;
    border-radius: 10px;
    font-size: 12px;
    justify-items: center;
    align-items: center;
    padding-top: 20px;
    height: 80px;
    width: 100px;
  }

  .aboutus-header{
    font-size: larger;
  }

  .aboutus-content {
    flex-direction: column;
  }

  .aboutus-text{
    font-size: 8px;
    margin-left: 10px;
    margin-right: 10px;
  }

  .aboutus-image{
    height: 20vh;
  }

  .aboutus-button{
    margin-left: 10px;
    background-color: #34d399;
    border: #000;
    border-radius: 5px;
    font-size: 10px;
    margin-top: 10px;
    outline: #000;
    outline-width: 1px;
    padding: 4px 4px 4px 4px;
  }

  .services-header{
    font-size: large;
  }

  .services-header-underline{
    width: 70px;
    margin-left: 40%;
  }

  .service-service-options-image{
    transform: scale(0.50);
  }

  .service-service-options-label{
    font-size: 8px;
    font-weight: bold;
  }

  .service-service-options-content{
    font-size: 8px;
  }

  .service-service-options-style{
    transform: scale(0.75);
    z-index: 0;
  }

  .services-stack{
    margin-left: 2px;
    margin-right: 2px;
  }

  .services-button{
    padding: 5px 5px 5px 5px;
    margin-left: 35%;
  }

  .services-help-button{
    margin-top: 40px;
    /* text-align: center; */
  }

  .contactus-input{
    margin-top: 10px;
    margin-right: 10px;
    margin-left: 5px;
  }

  .contactus-thankyou{
    font-size: 10px;
    margin-top: 5px;
  }

  .contactus-button{
    
    margin-top: 10px;
  }

  .contactus-image{
    height: 30vh;
    border-radius: 10px;
  }

  .contactus-image-box{
    margin-left: 0px;
    margin-top: 20px;
    border-radius: 20px;
  }

  .contactus-address{
    font-size: 10px;
    margin-left: 80px;
    margin-top: 10px;
  }

  .contactus-icon{
    transform: scale(0.75);
  }


  /* --------------------------- booking requirement -----------------------------------------------*/

  .booking-req-container {
    flex-direction: row;
  }

  .booking-req-white-box{
    width: 66.6667%;
    padding-bottom: 30px;
  }

  .booking-req-gray-box{
    width: 33.3333%;
  }

  .frequency-box{
    height: 30vh;
    width: 15vw;
  }

  .frequency-box-label{
    font-size: 20px;
  }