@media screen and (min-width: 1500px){
  .navbar-flex-container {
    padding-left: 100px;
}

.navbar-dropdown-mobile {
  display: none;
}

.hero-image {
  margin-left: 120%;
}

.hero-tagline-box {
  margin-left: 15rem;
  margin-top: 5rem;
  transform: scale(1.50);
}

.searchbar-style {
margin-left: 10rem;
margin-top: 20rem;
}

.service-box{
  background-color: #D9D9D9;
  margin-left: 100px;
  margin-right: 100px;
  border-radius: 10px;
  padding-left: 10px;
  padding-bottom: 10px;
 }

 .service-service-options-label{
  font-size: 10px;
 }

 .service-stack{
  flex-direction: row;
 }

 .whyapac-header{
  font-size:xx-large;
 }

.whyapac-image{
flex-direction: row;
}

.whyapac-text{
flex-direction: row;
}

.whyapac-flex-container{
flex-direction: column;
}

.why-apac-descriptionbox{
display: flex;
justify-content: center;
margin-left: 5px;
margin-right: 5px;
margin-top: -10px;
height: 180px;
width: 200px;
padding-left: 4px;
padding-right: 4px;
}

.why-apac-descriptionbox-text{
  font-size: 20px;
}

.why-apac-image-box{
margin-left: 5px;
margin-right: 5px;
height: 180px;
width: 200px;

}

.aboutus-header{
  font-size: xx-large;
}

.aboutus-content {
flex-direction: column;
}

.aboutus-text{
font-size: 14px;
margin-left: 50px;
margin-right: 50px;
}

.aboutus-image{
  height: 40vh;
  width: 40vw;
}

.aboutus-button{
margin-left: 100px;
height: 50px;
background-color: #34d399;
border: #000;
border-radius: 5px;
font-size: 14px;
margin-top: 10px;
outline: #000;
outline-width: 1px;
}

.services-stack{
margin-left: 520px;
margin-right: 20px;
}

.service-service-options-style{
transform: scale(1.25);
margin-left: 30px;
margin-top: 15px;
}

.services-button{
  padding: 5px 5px 5px 5px;
  margin-left: 15%;
}

.contactus-input{
  margin-top: 10px;

}

.contactus-thankyou{
  font-size: 10px;
}

.contactus-button{
  
  margin-top: 10px;
}

.contactus-flex-container{
  display: flex;
  flex-direction: row;
  margin-left: 350px;
}

.contactus-address{
  font-size: 12px;
  margin-left: 0px;
  margin-right: 10px;
  margin-top: 20px;
}

.booking-req-gray-box{
  padding-bottom: 210px;
}

.booking-req-header{
  flex-direction: row;
  font-size: 12px;
  margin-left: 30px;
}

.booking-req-white-box{
  font-size: 14px;
}

.booking-req-sub-header{
  font-weight: bold;
  margin-left: 30px;
}

.booking-req-sub-header2{
  margin-left: 35px;
  font-size: 12px;
}

.booking-req-dropdown{
  margin-left: 30px;
}

.frequency-box-label{
  font-size: 14px;
}

.frequency-box{
  height: 40vh;
  width: 30vw;
  margin-left: 10px;
  padding-left: 2px;
  padding-right: 2px;
}

.frequency-box-container{
  margin-left: 30px;
  margin-right: 30px;
}

.booking-req-inactive{
  margin-top: 40px;
}

.booking-cnf-active{
  margin-top: 40px;
}

.booking-input-details{
  margin-right: 390px;
}

}


@media screen and (max-width: 1500px) {

  .navbar-flex-container {
    padding-left: 100px;
}

.navbar-dropdown-mobile {
  display: none;
}

.hero-image {
  margin-left: 100%;
}

.hero-tagline-box {
  margin-left: 10rem;
}

.searchbar-style {
margin-left: 10rem;
}

.service-box{
  background-color: #D9D9D9;
  margin-left: 160px;
  margin-right: 180px;
  border-radius: 10px;
  padding-left: 0px;
  padding-right: 10px;
  padding-bottom: 10px;
 }

 .service-stack{
  flex-direction: row;
 }

 .whyapac-header{
  font-size:xx-large;
 }

.whyapac-image{
flex-direction: row;
}

.whyapac-text{
flex-direction: row;
}

.whyapac-flex-container{
flex-direction: column;
}

.why-apac-descriptionbox{
display: flex;
justify-content: center;
margin-left: 5px;
margin-right: 5px;
margin-top: -10px;
height: 160px;
width: 180px;
padding-left: 4px;
padding-right: 4px;
}

.why-apac-descriptionbox-text{
  font-size: 20px;
}

.why-apac-image-box{
margin-left: 5px;
margin-right: 5px;
height: 160px;
width: 180px;

}

.aboutus-header{
  font-size: xx-large;
}

.aboutus-content {
flex-direction: column;
}

.aboutus-text{
font-size: 14px;
margin-left: 50px;
margin-right: 50px;
}

.aboutus-image{
  height: 40vh;
  width: 40vw;
}

.aboutus-button{
margin-left: 100px;
height: 50px;
background-color: #34d399;
border: #000;
border-radius: 5px;
font-size: 14px;
margin-top: 10px;
outline: #000;
outline-width: 1px;
}

.services-stack{
margin-left: 490px;
margin-right: 20px;
}

.service-service-options-style{
transform: scale(1.25);
margin-left: 20px;
margin-top: 15px;
}

.services-button{
  padding: 5px 5px 5px 5px;
  margin-left: 15%;
}

.contactus-input{
  margin-top: 10px;

}

.contactus-thankyou{
  font-size: 10px;
}

.contactus-button{
  
  margin-top: 10px;
}

.contactus-flex-container{
  display: flex;
  flex-direction: row;
  margin-left: 350px;
}

.contactus-address{
  font-size: 12px;
  margin-left: 0px;
  margin-right: 10px;
  margin-top: 20px;
}

.booking-req-gray-box{
  padding-bottom: 190px;
}

.booking-req-inactive{
  margin-top: 40px;
}

.booking-cnf-active{
  margin-top: 40px;
}

.booking-req-header{
  flex-direction: row;
  font-size: 12px;
  margin-left: 30px;
}

.booking-req-white-box{
  font-size: 14px;
}

.booking-req-sub-header{
  font-weight: bold;
  margin-left: 30px;
}

.booking-req-sub-header2{
  margin-left: 35px;
  font-size: 12px;
}

.booking-req-dropdown{
  margin-left: 30px;
}

.frequency-box-label{
  font-size: 14px;
}

.frequency-box{
  height: 40vh;
  width: 30vw;
  margin-left: 10px;
  padding-left: 2px;
  padding-right: 2px;
}

.frequency-box-container{
  margin-left: 30px;
  margin-right: 30px;
}

.booking-input-details{
  margin-right: 190px;
}



}

@media screen and (max-width: 1120px) {
    .navbar-flex-container {
        padding-left: 100px;
    }

    .hero-image {
      margin-left: 100%;
    }
  
    .hero-tagline-box {
      margin-left: 10rem;
}

.searchbar-style {
  margin-left: 10rem;
}

.service-box{
  background-color: #D9D9D9;
  margin-left: 130px;
  margin-right: 140px;
  border-radius: 10px;
  padding-left: 10px;
 }

 .service-stack{
  flex-direction: row;
 }

 .whyapac-header{
  font-size:x-large;
 }

.whyapac-image{
  flex-direction: row;
}

.whyapac-text{
  flex-direction: row;
}

.whyapac-flex-container{
  flex-direction: column;
}

.why-apac-descriptionbox{
  margin-left: 5px;
  display: flex;
  justify-content: center;
  margin-right: 5px;
  margin-top: -10px;
  height: 140px;
  width: 160px;
  padding-left: 2px;
  padding-right: 2px;
}

.why-apac-descriptionbox-text{
  font-size: 15px;
}

.why-apac-image-box{
  margin-left: 5px;
  margin-right: 5px;
  height: 140px;
  width: 160px;
 
}

.aboutus-header{
  font-size: x-large;
}

.aboutus-content {
  flex-direction: column;
}

.aboutus-text{
  font-size: 12px;
  margin-left: 50px;
  margin-right: 50px;
}

.aboutus-image{
  height: 30vh;
  width: 40vw;
}



.aboutus-button{
  margin-left: 100px;
  height: 40px;
  background-color: #34d399;
  border: #000;
  border-radius: 5px;
  font-size: 12px;
  margin-top: 10px;
  outline: #000;
  outline-width: 1px;
}

.services-stack{
  margin-left: 340px;
  margin-right: 20px;
}

.service-service-options-style{
  transform: scale(1.25);
  margin-left: 20px;
  margin-top: 15px;
}

.services-button{
  padding: 5px 5px 5px 5px;
  margin-left: 20%;
}
.contactus-input{
  margin-top: 10px;
}

.contactus-thankyou{
  font-size: 10px;
}

.contactus-button{
  
  margin-top: 10px;
}

.contactus-flex-container{
  display: flex;
  flex-direction: row;
  margin-left: 150px;
}

.contactus-address{
  font-size: 12px;
  margin-left: 0px;
  margin-right: 10px;
  margin-top: 20px;
}

.booking-req-gray-box{
  padding-bottom: 190px;
}

.booking-req-header{
  flex-direction: row;
  font-size: 12px;
  margin-left: 30px;
}

.booking-req-white-box{
  font-size: 14px;
}

.booking-req-sub-header{
  font-weight: bold;
  margin-left: 30px;
}

.booking-req-sub-header2{
  margin-left: 35px;
  font-size: 12px;
}

.booking-req-dropdown{
  margin-left: 30px;
}

.frequency-box-label{
  font-size: 14px;
}

.frequency-box{
  height: 40vh;
  width: 30vw;
  margin-left: 10px;
  padding-left: 2px;
  padding-right: 2px;
}

.frequency-box-container{
  margin-left: 30px;
  margin-right: 30px;
}

.booking-input-details{
  margin-right: 190px;
}

}

/* @media screen and (max-width: 1119px) and (min-width: 993px) {
  .hero-image {
    margin-left: 120%;
  }
} */

@media screen and (max-width: 992px) {
    .NavbarItems {
      position: relative;
    }
  
    .nav-menu {
      display: flex;
      flex-direction: column;
      width: 70%;
      height: 90vh;
      position: absolute;
      top: 80px;
      left: -100%;
      opacity: 1;
      transition: all 0.5s ease;
      padding-bottom: 200px;
    }

    .navbar-dropdown-mobile{
      display: flex;
      flex-direction: column;
      background-color: #5eead4;
      position: absolute;
      width: 100%;
      left:0%;
      top: 120px;
      padding: 10px 10px 20px 10%;
    }

    .dropdown-header-mobile{
      font-weight: bold;
    }

    .dropdown-item-mobile{
      font-weight: normal;
    }

    .dropdown-container-mobile{
      text-align: start;
      font-size: 16px;
      
      justify-content: center;
    }
    .navbar-dropdown{
      display: none;
    }

    .navbar-flex-container {
        display: flex;
        flex-direction: column;
        position: absolute;
    }
  
    .nav-menu.active {
      background: #14b8a6;
      left: 0;
      opacity: 1;
      transition: all 0.5s ease;
      z-index: 1;
    }
  
    .nav-links {
      text-align: center; 
      padding : 2rem;
      width: 100%;
      display: table;
    }
  
    .nav-links:hover {
      background-color: #fff;
      color: #242424;
      border-radius: 0;
    }
  
    .navbar-logo {
        color: #000;
        justify-self: start;
        margin-left: 0px;
        cursor: pointer;
        text-decoration: none;
        font-size: 2rem;
        display: flex;
        align-items: center;
      }
  
    .menu-icon {
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      transform: translate(-100%, 60%);
      font-size: 1.8rem;
      cursor: pointer;
    }
  
    .fa-times {
      color: #fff;
      font-size: 2rem;
    }
  
    .nav-links-mobile {
      display: block;
      text-align: center;
      margin: 2rem auto;
      border-radius: 4px;
      width: 80%;
      text-decoration: none;
      font-size: 1.5rem;
      background-color: transparent;
      color: #fff;
      padding: 14px 20px;
      border: 1px solid #fff;
      transition: all 0.3s ease-out;
    }
  
    .nav-links-mobile:hover {
      background: #fff;
      color: #242424;
      transition: 250ms;
    }


    .hero-image {
      margin-left: 80%;
      transform: translateY(-250px) scale(0.25);
    }

    .hero-background {
      height: 300px;
    }

    .searchbar-style {
      margin-left: 9rem;
      margin-top: 12rem;
      /* transform: scale(0.75); */
    }

    .hero-tagline-box {
      margin-left: 7rem;
      transform: scale(0.75);
      margin-top: 2rem;
    }

    .service-box{
      background-color: #D9D9D9;
      margin-left: 240px;
      margin-right: 240px;
      border-radius: 10px;
      padding-left: 10px;
     }

    .whyapac-header{
      font-size: large;
     }

    .whyapac-image{
      flex-direction: row;
    }
  
    .whyapac-text{
      flex-direction: row;
    }
  
    .whyapac-flex-container{
      flex-direction: column;
    }
  
    .why-apac-descriptionbox{
      margin-left: 5px;
      display: flex;
      justify-content: center;
      margin-right: 5px;
      margin-top: -10px;
      height: 100px;
      width: 120px;
      padding-left: 2px;
      padding-right: 2px;
    }

    .why-apac-descriptionbox-text{
      font-size: 10px;
    }
  
    .why-apac-image-box{
      margin-left: 5px;
      margin-right: 5px;
      height: 100px;
      width: 120px;
      
    }

    .aboutus-header{
      font-size: larger;
    }

    .aboutus-content {
      flex-direction: column;
    }
  
    .aboutus-text{
      font-size: 12px;
      margin-left: 40px;
      margin-right: 40px;
    }
  
    .aboutus-button{
      margin-left: 80px;
      height: 40px;
      background-color: #34d399;
      border: #000;
      border-radius: 5px;
      font-size: 12px;
      margin-top: 10px;
      outline: #000;
      outline-width: 1px;
    }

    .services-stack{
      margin-left: 250px;
      margin-right: 20px;
    }

    .service-stack{
      flex-direction: column;
     }
  
    .service-service-options-style{
      transform: scale(1.25);
      margin-left: 20px;
      margin-top: 5px;
    }

    .services-button{
      padding: 8px 8px 8px 8px;
      margin-left: 25%;
    }

    .contactus-input{
      margin-top: 10px;
   
    }
  
    .contactus-thankyou{
      font-size: 10px;
    }
  
    .contactus-button{
     
      margin-top: 10px;
    }

    .contactus-flex-container{
      display: flex;
      flex-direction: row;
      margin-left: 50px;
    }
  
    .contactus-address{
      font-size: 10px;
      margin-left: 0px;
      margin-right: 10px;
      margin-top: 20px;
    }

    .booking-req-container {
      flex-direction: column;
    }

    .booking-req-gray-box{
      width: 100%;
    }

    .booking-req-white-box{
      width: 100%;
    }

    .booking-req-header{
      flex-direction: row;
      font-size: 12px;
      margin-left: 30px;
    }
  
    .booking-req-white-box{
      font-size: 14px;
    }
  
    .booking-req-sub-header{
      font-weight: bold;
      margin-left: 30px;
    }
  
    .booking-req-sub-header2{
      margin-left: 35px;
      font-size: 12px;
    }
  
    .booking-req-dropdown{
      margin-left: 30px;
    }
  
    .frequency-box-label{
      font-size: 14px;
    }
  
    .frequency-box{
      height: 40vh;
      width: 30vw;
      margin-left: 10px;
      padding-left: 2px;
      padding-right: 2px;
    }
  
    .frequency-box-container{
      margin-left: 30px;
      margin-right: 30px;
    }

    .booking-input-details{
      margin-right: 300px;
    }

  }

  @media screen and (max-width: 800px) {
    .searchbar-style{
      margin-left: 6rem;
    }

    .hero-tagline-box{
      margin-left: 4rem;
    }

    .hero-image {
      margin-left: 60%;
    }

    .service-box{
      background-color: #D9D9D9;
      margin-left: 140px;
      margin-right: 140px;
      border-radius: 10px;
      padding-left: 10px;
     }

    .whyapac-image{
      flex-direction: row;
    }
  
    .whyapac-text{
      flex-direction: row;
    }
  
    .whyapac-flex-container{
      flex-direction: column;
    }
  
    .why-apac-descriptionbox{
      display: flex;
      justify-content: center;
      margin-left: 5px;
      margin-right: 5px;
      margin-top: -10px;
      height: 100px;
      width: 120px;
      padding-left: 2px;
      padding-right: 2px;
    }
  
    .why-apac-image-box{
      margin-left: 5px;
      margin-right: 5px;
      height: 100px;
      width: 120px;
      
    }

    .aboutus-content {
      flex-direction: column;
    }
  
    .aboutus-text{
      font-size: 10px;
      margin-left: 30px;
      margin-right: 30px;
    }
  
    .aboutus-image{
      
    }
  
    .aboutus-button{
      margin-left: 50px;
      height: 30px;
      background-color: #34d399;
      border: #000;
      border-radius: 5px;
      font-size: 10px;
      margin-top: 10px;
      outline: #000;
      outline-width: 1px;
    }

    .services-stack{
      margin-left: 170px;
      margin-right: 20px;
    }
  
    .service-service-options-style{
      transform: scale(1);
    }

    .services-button{
      padding: 5px 5px 5px 5px;
      margin-left: 30%;
    }

    .contactus-input{
      margin-top: 10px;
      
    }
  
    .contactus-thankyou{
      font-size: 10px;
    }
  
    .contactus-button{
      
      margin-top: 10px;
    }

    .contactus-flex-container{
      display: flex;
      flex-direction: row;
      margin-left: 50px;
    }
  
    .contactus-address{
      font-size: 10px;
      margin-left: 0px;
      margin-right: 10px;
      margin-top: 20px;
    }

    .booking-req-container {
      flex-direction: column;
    }

    .booking-req-gray-box{
      width: 100%;
    }

    .booking-req-header{
      flex-direction: row;
      font-size: 12px;
      margin-left: 30px;
    }
  
    .booking-req-white-box{
      font-size: 14px;
    }
  
    .booking-req-sub-header{
      font-weight: bold;
      margin-left: 30px;
    }
  
    .booking-req-sub-header2{
      margin-left: 35px;
      font-size: 12px;
    }
  
    .booking-req-dropdown{
      margin-left: 30px;
    }
  
    .frequency-box-label{
      font-size: 14px;
    }
  
    .frequency-box{
      height: 40vh;
      width: 30vw;
      margin-left: 10px;
      padding-left: 2px;
      padding-right: 2px;
    }
  
    .frequency-box-container{
      margin-left: 30px;
      margin-right: 30px;
    }

    .booking-input-details{
      margin-right: 250px;
    }
   
  }



  @media screen and (max-width: 644px) {
    .searchbar-style {
      margin-left: 2rem;
      margin-top: 30px;
    }

    .hero-tagline-box {
      margin-left: -10px;
      margin-top: -120px;
    }

  .hero-components {
    transform: scale(0.75);
  }

  .hero-image {
    transform: translateY(-400px) scale(0.25);
    margin-left: 50%;
  }

  .hero-background {
    width: 100%;
    height: 220px;
  }

  .service-box{
    background-color: #D9D9D9;
    margin-left: 70px;
    margin-right: 70px;
    border-radius: 10px;
    padding-left: 10px;
   }

  .whyapac-image{
    flex-direction: row;
  }

  .whyapac-text{
    flex-direction: row;
  }

  .whyapac-flex-container{
    flex-direction: column;
  }

  .why-apac-descriptionbox{
    display: flex;
    justify-content: center;
    margin-left: 5px;
    margin-right: 5px;
    margin-top: -10px;
    height: 80px;
    width: 100px;
    padding-left: 2px;
    padding-right: 2px;
  }

  .why-apac-image-box{
    margin-left: 5px;
    margin-right: 5px;
    height: 80px;
    width: 100px;
    
  }

  .aboutus-content {
    flex-direction: column;
  }

  .aboutus-text{
    font-size: 10px;
    margin-left: 20px;
    margin-right: 20px;
  }

  .aboutus-image{
    height: 20vh;
  }

  .aboutus-button{
    margin-left: 40px;
    height: 30px;
    background-color: #34d399;
    border: #000;
    border-radius: 5px;
    font-size: 10px;
    margin-top: 10px;
    outline: #000;
    outline-width: 1px;
  }

  .services-stack{
    margin-left: 100px;
    margin-right: 20px;
  }

  .service-service-options-style{
    transform: scale(1);
  }

  .services-button{
    padding: 5px 5px 5px 5px;
    margin-left: 35%;
  }

  .contactus-input{
    margin-top: 10px;
   
  }

  .contactus-thankyou{
    font-size: 10px;
  }

  .contactus-button{
   
    margin-top: 10px;
  }

  .contactus-flex-container{
    display: flex;
    flex-direction: row;
  }

  .contactus-address{
    font-size: 10px;
    margin-left: 0px;
    margin-right: 10px;
    margin-top: 20px;
  }

  .booking-req-container {
    flex-direction: column;
  }

  .booking-req-gray-box{
    width: 100%;
  }

  .booking-req-header{
    flex-direction: column;
    font-size: 12px;
    margin-left: 30px;
  }

  .booking-req-white-box{
    font-size: 14px;
  }

  .booking-req-sub-header{
    font-weight: bold;
    margin-left: 30px;
  }

  .booking-req-sub-header2{
    margin-left: 35px;
    font-size: 12px;
  }

  .booking-req-inactive{
    margin-top: 0px;
  }

  .booking-cnf-active{
    margin-top: 0px;
  }

  .booking-req-dropdown{
    margin-left: 30px;
  }

  .frequency-box-label{
    font-size: 14px;
  }

  .frequency-box{
    height: 40vh;
    width: 30vw;
    margin-left: 10px;
    padding-left: 2px;
    padding-right: 2px;
  }

  .frequency-box-container{
    margin-left: 30px;
    margin-right: 30px;
  }

  .booking-input-details{
    margin-right: 150px;
  }

 

  }

@media screen and (max-width : 499px) {

  .hero-image{
    display: none;
  }

  .hero-background {
    height: 185px;
  }

  .hero-tagline-box {
    transform: scale(0.75);
    margin-left: -30px;
    margin-top: auto;
  }

  .searchbar-style {
    transform: scale(0.75);
    margin-top: 160px;
    margin-left: -47px;
  }

  .searchbar-button-booknow{
    white-space: nowrap;
  }

  .service-box{
    background-color: #D9D9D9;
    margin-left: 20px;
    margin-right: 20px;
    border-radius: 10px;
    padding-left: 10px;
   }

  .whyapac-image{
    flex-direction: row;
  }

  .whyapac-text{
    flex-direction: row;
  }

  .whyapac-flex-container{
    flex-direction: column;
  }

  .why-apac-descriptionbox{
    display: flex;
    justify-content: center;
    margin-left: 5px;
    margin-right: 5px;
    margin-top: -10px;
    height: 80px;
    width: 100px;
    padding-left: 2px;
    padding-right: 2px;
  }

  .why-apac-image-box{
    margin-left: 5px;
    margin-right: 5px;
    height: 80px;
    width: 100px;
    
  }

  .aboutus-content {
    flex-direction: column;
  }

  .aboutus-text{
    font-size: 8px;
    margin-left: 10px;
    margin-right: 10px;
  }

  .aboutus-image{
    height: 20vh;
  }

  .aboutus-button{
    margin-left: 30px;
    height: 30px;
    background-color: #34d399;
    border: #000;
    border-radius: 5px;
    font-size: 10px;
    margin-top: 10px;
    outline: #000;
    outline-width: 1px;
  }

  .services-stack{
    margin-left: 30px;
    margin-right: 20px;
  }

  .service-service-options-style{
    transform: scale(1);
  }

  .services-button{
    padding: 5px 5px 5px 5px;
    margin-left: 35%;
  }

  .contactus-input{
    margin-top: 10px;
    
  }

  .contactus-thankyou{
    font-size: 10px;
  }

  .contactus-button{
   
    margin-top: 10px;
  }
  .contactus-address{
    font-size: 10px;
    margin-left: 100px;
    margin-top: 10px;
  }

  .contactus-flex-container{
    display: flex;
    flex-direction: column;
    margin-left: 20px;
  }

  .booking-req-container {
    flex-direction: column;
  }

  .booking-req-gray-box{
    width: 100%;
  }

  .booking-req-header{
    flex-direction: column;
    font-size: 12px;
    margin-left: 30px;
  }

  .booking-req-white-box{
    font-size: 14px;
  }

  .booking-req-sub-header{
    font-weight: bold;
    margin-left: 30px;
  }

  .booking-req-sub-header2{
    margin-left: 35px;
    font-size: 12px;
  }

  .booking-req-dropdown{
    margin-left: 30px;
  }

  .frequency-box-label{
    font-size: 14px;
  }

  .frequency-box{
    height: 40vh;
    width: 30vw;
    margin-left: 10px;
    padding-left: 2px;
    padding-right: 2px;
  }

  .frequency-box-container{
    margin-left: 30px;
    margin-right: 30px;
  }
  
  .booking-req-inactive{
    margin-top: 0px;
  }

  .booking-cnf-active{
    margin-top: 0px;
  }

  .booking-input-details{
    margin-right: 50px;
  }
}

@media screen and (max-width:376px) {

  /* .hero-image {
    transform: translateY(-340px) scale(0.25);
    margin-left: 40%;
  } */

  .hero-image{
    display: none;
  }

  .hero-background {
    height: 185px;
  }

  .hero-tagline-box {
    transform: scale(0.75);
    margin-left: -30px;
    margin-top: auto;
  }

  .searchbar-style {
    /* transform: scale(0.75); */
    margin-top: 150px;
    margin-left: -40px;
    position: absolute;
  }

  .searchbar-button-booknow{
    white-space: nowrap;
  }

  .service-box{
    background-color: #D9D9D9;
    margin-left: 20px;
    margin-right: 20px;
    border-radius: 10px;
    padding-left: 10px;
   }

   .whyapac-header{
    font-size: large;
   }


  .why-apac-image-box{
    height: 80px;
    width: 100px;
    margin-top: 5px;
    
  }
 
  .why-apac-descriptionbox{
    display: flex;
    justify-content: center;
    background-color: #5eead4;
    border-radius: 10px;
    font-size: 12px;
    justify-items: center;
    align-items: center;
    padding-top: 10px;
    height: 80px;
    width: 100px;
    margin-top: 5px;
    padding-left: 2px;
    padding-right: 2px;
  }

  .whyapac-image{
    flex-direction: column;
  }

  .whyapac-text{
    flex-direction: column;
  }
  
  .whyapac-flex-container{
    flex-direction: row;
  }

  .aboutus-content {
    flex-direction: column;
  }

  .aboutus-text{
    font-size: 8px;
    margin-left: 10px;
    margin-right: 10px;
  }

  .aboutus-image{
    height: 20vh;
  }

  .aboutus-button{
    margin-left: 20px;
    background-color: #34d399;
    border: #000;
    border-radius: 5px;
    font-size: 10px;
    margin-top: 10px;
    outline: #000;
    outline-width: 1px;
    height: 30px;
  }

  .services-button{
    padding: 5px 5px 5px 5px;
    margin-left: 35%;
  }

  .contactus-input{
    margin-top: 10px;
  }

  .contactus-thankyou{
    font-size: 10px;
  }

  .contactus-button{
    
    margin-top: 10px;
  }

  .contactus-address{
    font-size: 10px;
    margin-left: 100px;
    margin-top: 10px;
  }

  .contactus-flex-container{
    display: flex;
    flex-direction: column;
    margin-left: 20px;
  }


  .booking-req-container {
    flex-direction: column;
    padding-bottom: 10px;
  }

  .booking-req-header{
    flex-direction: column;
    font-size: 12px;
    margin-left: 30px;
  }

  .booking-req-white-box{
    font-size: 14px;
  }

  .booking-req-sub-header{
    font-weight: bold;
    margin-left: 30px;
  }

  .booking-req-sub-header2{
    margin-left: 35px;
    font-size: 12px;
  }

  .booking-req-dropdown{
    margin-left: 30px;
  }

  .frequency-box-label{
    font-size: 14px;
  }

  .frequency-box{
    height: 40vh;
    width: 30vw;
    margin-left: 10px;
    padding-left: 2px;
    padding-right: 2px;
  }

  .frequency-box-container{
    margin-left: 30px;
    margin-right: 30px;
  }

  .booking-req-inactive{
    margin-top: 0px;
  }

  .booking-cnf-active{
    margin-top: 0px;
  }

  .booking-input-details{
    margin-right: 30px;
  }


  
  
  }